img{
	max-width:100%;
	height:auto;
}

img[class*="wp-image"]{
	width:100%;
	height:auto;
	@media only screen and (min-width: 376px) {
		width:auto;
		max-width:100%;
	}
}

