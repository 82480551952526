.back-top{
    display:block;
    background:#333;
    border-radius:5px;
    font-size:1.6em;
    position:fixed;
    z-index:100;
    bottom:0px;
    right:3.5%;
    padding:5px 25px 10px;
    color:#fff;
    transition: all 500ms ease-in-out;
    opacity: 0;
    visibility:hidden;
    a{
        color:#ffffff;
    }
}

.back-top.visible {
    opacity: 0.75;
    bottom:10px;
    visibility: visible;
}

.colophon{
    .colo{
        // padding-top:20px;
        // padding-bottom:35px;
    }
}

.copyright{
    background:#000;
    color:#fff;
    padding-top:25px;
    padding-bottom:25px;
    font-size:1.2em;
    a{
        color:#ccc;
        &:hover,&:focus{
            color:#fff;
            text-decoration: underline;
        }
    }
}