
.txt-xs, .text-xs
{
	font-size: .6em;
}
.txt-sm, .text-sm
{
	font-size: .8em;
}
.txt-md, .text-md
{
	font-size: 1.0em;
	@media only screen and (min-width: 768px)
	{
		font-size: 1.4em;
	}
}
.txt-lg, .text-lg
{
	font-size: 1.2em;
	@media only screen and (min-width: 768px)
	{
		font-size: 1.6em;
	}
}
.txt-xl, .text-xl
{
	font-size: 1.4em;
	@media only screen and (min-width: 768px)
	{
		font-size: 2.0em;
	}
}
.bg-snow
{
	background-color: $snow;
}
.bg-silver
{
	background-color: $silver;
}
.bg-iron
{
	background-color: $iron;
}
.bg-jumbo
{
	background-color: $jumbo;
}
.bg-steel
{
	background-color: $steel;
}
.bg-oil
{
	background-color: $oil;
}
.bg-black
{
	background-color: $black;
}
.bg-blue
{
	background-color: $blue;
}
.text-white, .txt-white
{
	color: $white!important;
}
.text-snow, .txt-snow
{
	color: $snow!important;
}
.text-silver, .txt-silver
{
	color: $silver!important;
}
.text-iron, .txt-iron
{
	color: $iron!important;
}
.text-jumbo, .txt-jumbo
{
	color: $jumbo!important;
}
.text-steel, .txt-steel
{
	color: $steel!important;
}
.text-oil, .txt-oil
{
	color: $oil!important;
}
.text-primary, .txt-primary
{
color: $brand-primary!important;
}
.text-success, .txt-success
{
color: $brand-success!important;
}
.text-info, .txt-info
{
color: $brand-info!important;
}
.text-warning, .txt-warning
{
color: $brand-warning!important;
}
.text-danger, .txt-danger
{
color: $brand-danger!important;
}
.text-shadow, .txt-shadow
{
	text-shadow: 0 1px 5px #000;
}

// Usage <div class="intrinsic-container intrinsic-container-[size]"><iframe>...</iframe></div>
.intrinsic-container {
    position: relative;
    overflow: hidden;
    height: 0;
    .intrinsic-container-16x9 {
        padding-bottom: 56.25%;
    }
    .intrinsic-container-4x3 {
        padding-bottom: 75%;
    }
    .intrinsic-container-8x11 {
        padding-bottom: 100%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Availible styles for adding padding

.padding-none {
    padding-top: 0;
    padding-bottom: 0;
}

.padding-top-none {
    padding-top: 0;
}

.padding-bottom-none {
    padding-bottom: 0;
}

.padding-xs {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-top-xs {
    padding-top: 5px;
}

.padding-bottom-xs {
    padding-bottom: 5px;
}

.padding-sm {
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (min-width: 767px) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.padding-top-sm {
    padding-top: 10px;
    @media only screen and (min-width: 767px) {
        padding-top: 25px;
    }
}

.padding-bottom-sm {
    padding-bottom: 10px;
    @media only screen and (min-width: 767px) {
        padding-bottom: 25px;
    }
}

.padding-md {
    padding-top: 15px;
    padding-bottom: 15px;
    @media only screen and (min-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.padding-top-md {
    padding-top: 15px;
    @media only screen and (min-width: 767px) {
        padding-top: 50px;
    }
}

.padding-bottom-md {
    padding-bottom: 15px;
    @media only screen and (min-width: 767px) {
        padding-bottom: 50px;
    }
}

.padding-lg {
    padding-top: 20px;
    padding-bottom: 20px;
    @media only screen and (min-width: 767px) {
        padding-top: 75px;
        padding-bottom: 75px;
    }
}

.padding-top-lg {
    padding-top: 20px;
    @media only screen and (min-width: 767px) {
        padding-top: 75px;
    }
}

.padding-bottom-lg {
    padding-bottom: 20px;
    @media only screen and (min-width: 767px) {
        padding-bottom: 75px;
    }
}

.padding-xl {
    padding-top: 25px;
    padding-bottom: 25px;
    @media only screen and (min-width: 767px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
}

.padding-top-xl {
    padding-top: 25px;
    @media only screen and (min-width: 767px) {
        padding-top: 125px;
    }
}

.padding-bottom-xl {
    padding-bottom: 25px;
    @media only screen and (min-width: 767px) {
        padding-bottom: 125px;
    }
}

.padding-xxl {
    padding-top: 50px;
    padding-bottom: 50px;
    @media only screen and (min-width: 767px) {
        padding-top: 200px;
        padding-bottom: 200px;
    }
}

.padding-top-xxl {
    padding-top: 50px;
    @media only screen and (min-width: 767px) {
        padding-top: 200px;
    }
}

.padding-bottom-xxl {
    padding-bottom: 50px;
    @media only screen and (min-width: 767px) {
        padding-bottom: 200px;
    }
}


/*
 *  Column row clears
 */

.col-xs-6.col-md-6,
.two-cols {
    // Two Columns
    &:nth-child(2n+3) {
        clear: both;
    }
}

.col-xs-6.col-md-4,
.three-cols {
    // Three Columns
    @media only screen and (max-width: 991px) {
        &:nth-child(2n+3) {
            clear: both;
        }
    }
    @media only screen and (min-width: 992px) {
        &:nth-child(3n+4) {
            clear: both;
        }
    }
}

.col-xs-6.col-md-3,
.four-cols {
    // Four Columns
    @media only screen and (max-width: 991px) {
        &:nth-child(2n+3) {
            clear: both;
        }
    }
    @media only screen and (min-width: 992px) {
        &:nth-child(4n+5) {
            clear: both;
        }
    }
}

.col-xs-6.col-sm-4.col-md-2,
.six-cols {
    // Six Columns
    @media only screen and (max-width: 767px) {
        &:nth-child(2n+1) {
            clear: both;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
        &:nth-child(3n+4) {
            clear: both;
        }
    }
    @media only screen and (min-width: 992px) {
        &:nth-child(6n+7) {
            clear: both;
        }
    }
}

@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}
