/* Element Wrapper Styles */

.videoElement {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right:0;
  z-index: -1;
}
.jumbo-background{
  display:table;
  width:100%;
  position: relative;
  -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
    z-index: 0;
}
.jumbo-media{
  display: table-cell;
  vertical-align: middle;
  position:relative;
  z-index:1;
}
.jumbo-overlay{
  z-index:2;
}

.element-background{
  position: relative;
}
.element-media{}
.element-overlay{}

.scroll-down{
  position: absolute;
  display:block;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  text-align:center;
  color:rgba(255,255,255,0.60);
  text-shadow: 0px 3px 2px rgba(0,0,0,0.10);
  font-size:4em;
  -webkit-transform:scale(2.0, 1.0);
  -moz-transform:scale(2.0, 1.0);
  -ms-transform:scale(2.0, 1.0);
  -o-transform:scale(2.0, 1.0);
  transform:scale(2.0,1.0);
  z-index:3;
}
.scroll-down:hover, .scroll-down:focus{
  color:rgba(255,255,255,1);
  text-shadow: 0px 3px 2px rgba(0,0,0,0.50);
}

.glyphicon-menu-down{
	color:rgba(255,255,255,0.25);
}
a.scroll-down:hover .glyphicon-menu-down{
	color:rgba(200,200,200,1);
}

.dropdown .carat {
  border-left: 1px solid rgb(229, 229, 229);
  width: 30px;
  height: 36px;
  display: block;
  position: absolute;
  top: -1px;
  right: 0;
  display:none!important;
}

// Blocks
//
.blocks_element{
	.block_column{
		.block{
			margin-bottom:15px;
			&.color_invert{
				color:#fff!important;
			}
		}
		.block-audio{
			&.media-abs-bottom{
				position:relative;
				padding-bottom:45px;

				.block_media_audio{
					position: absolute;
					bottom:75px;
					left:0;
					right:0;
					&:after{
						content:'';
						width:100%;
						height:1px;
						clear:both;
					}
				}
			}
		}
	}
}

/* Block Audio Player */

.audioplayer
{
  height: 2.5em; /* 40 */
  color: #fff;
  border: 1px solid #222;
  position: relative;
  z-index: 1;
  background: #333;
}
  .audioplayer-mini
  {
    width: 2.5em; /* 40 */
    margin: 0 auto;
  }
  .audioplayer > div
  {
    position: absolute;
  }
  .audioplayer-playpause
  {
    width: 2.5em; /* 40 */
    height: 100%;
    text-align: left;
    //text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
    left: 0;
  }
    .audioplayer:not(.audioplayer-mini) .audioplayer-playpause
    {
      border-right: 1px solid #555;
      border-right-color: rgba( 255, 255, 255, .1 );
    }
    .audioplayer-mini .audioplayer-playpause
    {
      width: 100%;
    }
    .audioplayer-playpause:hover,
    .audioplayer-playpause:focus
    {
      background-color: #222;
    }
    .audioplayer-playpause a
    {
      display: block;
      background:#ff0;
      font-family: "Glyphicons Halflings";
      color: #fff;
      font-size: 1.5em;
      border: none !important;
    }
    .audioplayer-playpause a:hover, .audioplayer-playpause a:focus, .audioplayer-playpause a:active{
      border: none !important;
    }
    .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a
    {
      width: 0;
      height: 0;
      position: absolute;
      top: 5%;
      left: 20%;
    }
    .audioplayer-playing .audioplayer-playpause a
    {
      width: 0;
      height: 0;
      position: absolute;
      top: 5%;
      left: 20%;
    }
      .audioplayer-playing .audioplayer-playpause a:before,
      .audioplayer-playing .audioplayer-playpause a:after
      {
        // width: 40%;
        // height: 100%;
        // background-color: #fff;
        // content: '';
        // position: absolute;
        // top: 0;
      }
      .audioplayer-playing .audioplayer-playpause a:before
      {
        left: 0;
      }
      .audioplayer-playing .audioplayer-playpause a:after
      {
        right: 0;
      }
  .audioplayer-time
  {
    width: 4.375em; /* 70 */
    height: 100%;
    line-height: 2.375em; /* 38 */
    text-align: center;
    z-index: 2;
    top: 0;
  }
    .audioplayer-time-current
    {
      border-left: 1px solid #111;
      border-left-color: rgba( 0, 0, 0, .25 );
      left: 2.5em; /* 40 */
    }
    .audioplayer-time-duration
    {
      border-right: 1px solid #555;
      border-right-color: rgba( 255, 255, 255, .1 );
      right: 2.5em; /* 40 */
    }
      .audioplayer-novolume .audioplayer-time-duration
      {
        border-right: 0;
        right: 0;
      }
  .audioplayer-bar
  {
    height: 0.875em; /* 14 */
    background-color: #222;
    cursor: pointer;
    z-index: 1;
    top: 50%;
    right: 6.875em; /* 110 */
    left: 6.875em; /* 110 */
    margin-top: -0.438em; /* 7 */
  }
    .audioplayer-novolume .audioplayer-bar
    {
      right: 4.375em; /* 70 */
    }
    .audioplayer-bar div
    {
      width: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .audioplayer-bar-loaded
    {
      background-color: #333;
      z-index: 1;
    }
    .audioplayer-bar-played
    {
      background: #007fd1;
      z-index: 2;
    }
  .audioplayer-volume
  {
    width: 2.5em; /* 40 */
    height: 100%;
    border-left: 1px solid #111;
    border-left-color: rgba( 0, 0, 0, .25 );
    text-align: left;
    //text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
    right: 0;
  }
    .audioplayer-volume:hover,
    .audioplayer-volume:focus
    {
      background-color: #222;
    }
    .audioplayer-volume-button
    {
      width: 100%;
      height: 100%;
    }
      .audioplayer-volume-button a
      {
        display: block;
        position: relative;
        z-index: 1;
        top: 5%;
        left: 20%;
        font-family: "Glyphicons Halflings";
        color: #fff;
        font-size: 1.5em;
      }
        .audioplayer-volume-button a:before,
        .audioplayer-volume-button a:after
        {
          position: absolute;
        }
        .audioplayer-volume-button a:before
        {
          border: 2px solid #f00;
          display: block;
          width: 100%;
          height: 100%;
          // width: 0;
          // height: 0;
          // border: 0.5em solid transparent; /* 8 */
          // border-left: none;
          // border-right-color: #fff;
          // z-index: 2;
          // top: 50%;
          // right: -0.25em;
          // margin-top: -0.5em; /* 8 */
        }
        .audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after
        {
          border: 2px solid #00f;
          display: block;
          width: 100%;
          height: 100%;
          /* "volume" icon by Nicolas Gallagher, http://nicolasgallagher.com/pure-css-gui-icons */
          // width: 0.313em; /* 5 */
          // height: 0.313em; /* 5 */
          // border: 0.25em double #fff; /* 4 */
          // border-width: 0.25em 0.25em 0 0; /* 4 */
          // left: 0.563em; /* 9 */
          // top: 0em; /* 1 */
          // -webkit-border-radius: 0 0.938em 0 0; /* 15 */
          // -moz-border-radius: 0 0.938em 0 0; /* 15 */
          // border-radius: 0 0.938em 0 0; /* 15 */
          // -webkit-transform: rotate( 45deg );
          // -moz-transform: rotate( 45deg );
          // -ms-transform: rotate( 45deg );
          // -o-transform: rotate( 45deg );
          // transform: rotate( 45deg );
        }
    .audioplayer-volume-adjust
    {
      height: 6.25em; /* 100 */
      cursor: default;
      position: absolute;
      left: 0;
      right: -1px;
      top: -9999px;
      background: #222;
      background: -webkit-gradient( linear, left top, left bottom, from( #444 ), to( #222 ) );
      background: -webkit-linear-gradient( top, #444, #222 );
      background: -moz-linear-gradient( top, #444, #222 );
      background: -ms-radial-gradient( top, #444, #222 );
      background: -o-linear-gradient( top, #444, #222 );
      background: linear-gradient( top, #444, #222 );
      -webkit-border-top-left-radius: 2px;
      -webkit-border-top-right-radius: 2px;
      -moz-border-radius-topleft: 2px;
      -moz-border-radius-topright: 2px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
      .audioplayer-volume:not(:hover) .audioplayer-volume-adjust
      {
        opacity: 0;
      }
      .audioplayer-volume:hover .audioplayer-volume-adjust
      {
        top: auto;
        bottom: 100%;
      }
      .audioplayer-volume-adjust > div
      {
        width: 40%;
        height: 80%;
        background-color: #222;
        cursor: pointer;
        position: relative;
        z-index: 1;
        margin: 30% auto 0;
      }
        .audioplayer-volume-adjust div div
        {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #007fd1;
        }
    .audioplayer-novolume .audioplayer-volume
    {
      display: none;
    }

  .audioplayer-play,
  .audioplayer-pause,
  .audioplayer-volume a
  {
    -webkit-filter: drop-shadow( 1px 1px 0 #000 );
    -moz-filter: drop-shadow( 1px 1px 0 #000 );
    -ms-filter: drop-shadow( 1px 1px 0 #000 );
    -o-filter: drop-shadow( 1px 1px 0 #000 );
    filter: drop-shadow( 1px 1px 0 #000 );
  }
  .audioplayer-bar,
  .audioplayer-bar div,
  .audioplayer-volume-adjust div
  {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .audioplayer-bar,
  .audioplayer-volume-adjust > div
  {
    -webkit-box-shadow: -1px -1px 0 rgba( 0, 0, 0, .5 ), 1px 1px 0 rgba( 255, 255, 255, .1 );
    -moz-box-shadow: -1px -1px 0 rgba( 0, 0, 0, .5 ), 1px 1px 0 rgba( 255, 255, 255, .1 );
    box-shadow: -1px -1px 0 rgba( 0, 0, 0, .5 ), 1px 1px 0 rgba( 255, 255, 255, .1 );
  }
  .audioplayer-volume-adjust div div,
  .audioplayer-bar-played
  {
    -webkit-box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5 );
    -moz-box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5 );
    box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5 );
  }
  .audioplayer-volume-adjust
  {
    -webkit-box-shadow: -2px -2px 2px rgba( 0, 0, 0, .15 ), 2px -2px 2px rgba( 0, 0, 0, .15 );
    -moz-box-shadow: -2px -2px 2px rgba( 0, 0, 0, .15 ), 2px -2px 2px rgba( 0, 0, 0, .15 );
    box-shadow: -2px -2px 2px rgba( 0, 0, 0, .15 ), 2px -2px 2px rgba( 0, 0, 0, .15 );
  }
  .audioplayer *,
  .audioplayer *:before,
  .audioplayer *:after
  {
    -webkit-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -moz-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -ms-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -o-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    transition: color .25s ease, background-color .25s ease, opacity .5s ease;
  }




// Gallery
//

.gallery img[data-mce-selected]:focus
{
	outline: none;
}

.gallery a
{
	cursor: default;
}

.gallery
{
	line-height: 1;

	overflow-x: hidden;

	margin: auto -6px;
	padding: 6px 0;
}

.ie7 .gallery,
.ie8 .gallery
{
	margin: auto;
}

.gallery .gallery-item
{
	float: left;

	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
	margin: 0;
	padding: 6px;

	text-align: center;
}

.ie7 .gallery .gallery-item,
.ie8 .gallery .gallery-item
{
	padding: 6px 0;
}

.gallery .gallery-caption,
.gallery .gallery-icon
{
	margin: 0;
}

.gallery .gallery-caption
{
	font-size: 13px;

	margin: 4px 0;
}

.gallery-columns-1 .gallery-item
{
	width: 100%;
}

.gallery-columns-2 .gallery-item
{
	width: 50%;
}

.gallery-columns-3 .gallery-item
{
	width: 33.333%;
}

.ie8 .gallery-columns-3 .gallery-item,
.ie7 .gallery-columns-3 .gallery-item
{
	width: 33%;
}

.gallery-columns-4 .gallery-item
{
	width: 25%;
}

.gallery-columns-5 .gallery-item
{
	width: 20%;
}

.gallery-columns-6 .gallery-item
{
	width: 16.665%;
}

.gallery-columns-7 .gallery-item
{
	width: 14.285%;
}

.gallery-columns-8 .gallery-item
{
	width: 12.5%;
}

.gallery-columns-9 .gallery-item
{
	width: 11.111%;
}

.gallery-image
{
	max-width: 100%;
	height: auto;
	padding: 0;
	border: none;
	margin-bottom:15px;
}

//Lightbox
//


.lightboxOverlay
{
	position: absolute;
	z-index: 9999;
	top: 0;
	left: 0;

	display: none;

	opacity: .8;
	background-color: black;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}

.lightbox
{
	font-weight: normal;
	line-height: 0;

	position: absolute;
	z-index: 10000;
	left: 0;

	width: 100%;

	text-align: center;
}

.lightbox .lb-image
{
	display: block;

	max-width: inherit;
	height: auto;

	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;

		-ms-border-radius: 3px;
		 -o-border-radius: 3px;
}

.lightbox a img
{
	border: none;
}

.lb-backgroundContainer
{
	position: relative;

	width: 250px;
	height: 250px;
	margin: 0 auto;

	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
			border-radius: 4px;
	background-color: white;

	*zoom: 1;
		-ms-border-radius: 4px;
		 -o-border-radius: 4px;
}

.lb-backgroundContainer:after
{
	display: table;
	clear: both;

	content: '';
}

.lb-container
{
	padding: 4px;
}

.lb-loader
{
	line-height: 0;

	position: absolute;
	top: 43%;
	left: 0;

	width: 100%;
	height: 25%;

	text-align: center;
}

.lb-cancel
{
	display: block;

	width: 32px;
	height: 32px;
	margin: 0 auto;

	background: url(../images/loading.gif) no-repeat;
}

.lb-nav
{
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.lb-container > .nav
{
	left: 0;
}

.lb-nav a
{
	outline: none;
	background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next
{
	display: block;

	height: 100%;

	cursor: pointer;
}

.lb-nav a.lb-prev
{
	left: 0;

	float: left;

	width: 34%;

	-webkit-transition: opacity .6s;
	   -moz-transition: opacity .6s;
		 -o-transition: opacity .6s;
			transition: opacity .6s;

	opacity: 0;
	background: url(../images/prev.png) left 48% no-repeat;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

.lb-nav a.lb-prev:hover
{
	opacity: 1;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.lb-nav a.lb-next
{
	right: 0;

	float: right;

	width: 64%;

	-webkit-transition: opacity .6s;
	   -moz-transition: opacity .6s;
		 -o-transition: opacity .6s;
			transition: opacity .6s;

	opacity: 0;
	background: url(../images/next.png) right 48% no-repeat;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

.lb-nav a.lb-next:hover
{
	opacity: 1;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.lb-dataContainer
{
	width: 100%;
	margin: 0 auto;
	padding-top: 5px;

	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
			border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
			border-bottom-left-radius: 4px;

	*zoom: 1;
}

.lb-dataContainer:after
{
	display: table;
	clear: both;

	content: '';
}

.lb-data
{
	padding: 0 4px;

	color: #ccc;
}

.lb-data .lb-details
{
	line-height: 1.1em;

	float: left;

	width: 85%;

	text-align: left;
}

.lb-data .lb-caption
{
	font-size: 13px;
	font-weight: bold;
	line-height: 1em;
}

.lb-data .lb-number
{
	font-size: 12px;

	display: block;
	clear: left;

	padding-bottom: 1em;

	color: #999;
}

.lb-data .lb-close
{
	display: block;
	float: right;

	width: 30px;
	height: 30px;

	-webkit-transition: opacity .2s;
	   -moz-transition: opacity .2s;
		 -o-transition: opacity .2s;
			transition: opacity .2s;
	text-align: right;

	opacity: .7;
	outline: none;
	background: url(../images/close.png) top right no-repeat;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
}

.lb-data .lb-close:hover
{
	cursor: pointer;

	opacity: 1;

	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

/*
 * Slider styles
 */

/* Carousel/Slider styles */

.carousel-fade .carousel-overlay .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-overlay .item,
.carousel-fade .carousel-overlay .active.left,
.carousel-fade .carousel-overlay .active.right {
  opacity: 0;
}
.carousel-fade .carousel-overlay .active,
.carousel-fade .carousel-overlay .next.left,
.carousel-fade .carousel-overlay .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-overlay .next,
.carousel-fade .carousel-overlay .prev,
.carousel-fade .carousel-overlay .active.left,
.carousel-fade .carousel-overlay .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

/* multi image carousel */

.carousel-multi-image{
  .carousel-inner{
    > .item{
      transition: 1000ms ease-in-out left;
    }
    .active{
      &.left{
        left:-33%;
      }
      &.right{
        left:33%;
      }
    }
    .next{
      left: 33%;
    }
    .prev{
      left: -33%;
    }
    @media all and (transform-3d), (-webkit-transform-3d) {
      > .item{
        // use your favourite prefixer here
        transition: 1000ms ease-in-out left;
        transition: 1000ms ease-in-out all;
        backface-visibility: visible;
        transform: none!important;
      }
    }
  }
}
.testimonials-slider{
  .carousel-content{
    height:200px;
    max-height:200px;
    overflow:hidden;
    display:table-cell;
    vertical-align: middle;
    padding-left:45px;
    padding-right:45px;
  }
  .testimonial-slide.item.active{
    //height:auto;
  }
}

.testimonials-slider .carousel .carousel-control{
  opacity: 0.35;
  filter: alpha(opacity=35);
  transition: opacity 0.5s;
  &.left{
    background:transparent;
    &:hover, &:focus{
      opacity: 0.85;
      filter: alpha(opacity=75);
    }
  }
  &.right{
    background:transparent;
    &:hover, &:focus{
      opacity: 0.85;
      filter: alpha(opacity=75);
    }
  }
  .glyphicon-chevron-right{
    right:10px;
  }
  .glyphicon-chevron-left{
    left:10px;
  }
}
