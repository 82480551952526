// Navbar =====================================================================

.masthead-wrap{
  //background:rgba(255,255,255,0.90);
  background:transparent url(../images/background-repeat-54.png) repeat center top;
  position:fixed;
  z-index:500;
  top:0px;
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
}

.header-logo{
  text-align:center;
  margin-bottom:2rem;
  @media only screen and (min-width: 768px) {
    text-align:left;
    margin-bottom:0px;
    margin-top:15px;
  }
  @media only screen and (min-width: 992px) {
    margin-top:8px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top:2px;
  }
  img{
    max-width:200px;
    @media only screen and (min-width: 768px) {
      max-width:100%;
    }
  }
}
.header-copy{
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size:0.8em;
  @media only screen and (min-width: 768px) {
    text-align:right;
    font-size:1.2em;
  }
  .header-email,.header-phone{
    padding-bottom:5px;
    display:inline-block;
  }
}

.navbar-custom {
  margin:5px 0 0;
  min-height:30px;
  box-sizing: border-box;
  ul{
    text-transform: uppercase;
    font-size:1.2em;
    font-weight:bold;
    box-sizing: border-box;
    @media only screen and (min-width: 768px) {
      font-size:1em;
    }
    @media only screen and (min-width: 992px) {
      font-size:1.2em;
    }
    li{
      box-sizing: border-box;
      a{
        box-sizing: border-box;
        color:$oil;
        padding:5px;
        margin:2px;
        &:hover{
          margin:0px;
          color: $brand-primary;
          background: transparent;
          border:2px solid $brand-primary;
        }
      }
      &.active a{
        margin:0px;
        color:$brand-primary;
        background:transparent!important;
        border:2px solid $brand-primary;
      }
    }
  }
  .navbar-brand{
    font-weight:bold;
    color:&oil;
    padding:8px 0px;
    img{
      max-height:32px;
      width:auto;
    }
  }
  .navbar-toggle {
    background-color:$brand-primary;
    border-radius: 5px;
    margin-right:0px;
    .icon-bar{
      background:$white!important;
    }
  }

  @media (min-width: 768px){
    .primary-nav{
      padding:0px;
    }
    .navbar-nav {
        margin: 0 auto;
        display: table;
        table-layout: auto;
        float: none;
        width: 100%;
    }
    .navbar-nav>li {
        display: table-cell;
        float: none;
        text-align: center;
    }
  }
}



