.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    //font-family: "Bevan","Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-family: 'Oswald', "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.4;
    color: inherit;
}

.main{
	padding-left:0px;
	padding-right:0px;
}

a:focus {
    outline: none;
    outline: 0px;
    outline-offset: 0px;
}

.num-list ol {
    margin-left: 20px;
    padding-right: 0;
    list-style-type: none;
}

.num-list ol li {
    counter-increment: step-counter;
}

.num-list ol li::before {
	content: counter(step-counter);
	margin-left: -60px;
	font-size: 24px;
	background-color: #b2553d;
	color: #ffffff;
	font-weight: 700;
	padding: 0;
	display: block;
	border-radius: 22px;
	height: 44px;
	width: 44px;
	text-align: center;
	float: left;
	clear: both;
	border: 4px solid #000000;
}

.page-header {
  margin-top: 0px;
}

.navbar-default .navbar-nav > li.menu-contact-bob > a{
	background-color:$brand-primary;
	&:hover, &:focus{
		background-color:darken($brand-primary, 10%);
	}
}

.page.recent-work{
	position:relative;
	.block-column-video, .block-column-audio {
		clear:left;
		@media only screen and (min-width: 768px) {
			padding-top: 9%;
			padding-left: 2%;
			padding-right: 2%;
			min-height: 260px!important;
			margin-bottom: 20px;
  		}
  		@media only screen and (min-width: 992px) {
  			min-height: 360px!important;
  		}
  		@media only screen and (min-width: 1200px) {
  			padding-top: 8%;
			padding-left: 3%;
			padding-right: 3%;
  		}
	}
	.block-column-video{
		@media only screen and (min-width: 768px) {
			background:transparent url(../images/tv-graphic02.png) no-repeat center top;
			background-size: contain;
		}
	}
	.block-column-audio{
		@media only screen and (min-width: 768px) {
			background:transparent url(../images/radio-graphic01.png) no-repeat center top;
			background-size: contain;
		}
	}

}
.page.projects-2{
	position:relative;
	.block-wrap-video, .block-wrap-audio {
		@media only screen and (min-width: 768px) {
			padding-top: 28%;
			padding-left: 8%;
			padding-right: 8%;
			min-height: 260px!important;
			//margin-bottom:20px;
  		}
  		@media only screen and (min-width: 992px) {
  			min-height: 300px!important;
  		}
  		@media only screen and (min-width: 1200px) {
			min-height: 360px!important;
  		}
	}
	.block-wrap-video{
		@media only screen and (min-width: 768px) {
			background:transparent url(../images/tv-graphic03.png) no-repeat center top;
			background-size: contain;
		}
	}
	.block-wrap-audio{
		@media only screen and (min-width: 768px) {
			background:transparent url(../images/tv-graphic03.png) no-repeat center top;
			background-size: contain;
		}
	}
}
.btn{
  &.btn-xs{
    border-radius:5px;
  }
  &.btn-sm{
    border-radius:8px
  }
  &.btn-md{
    border-radius:12px;
  }
  &.btn-lg{
    border-radius:16px;
  }
  &.btn-xl{
    border-radius:20px;
  }
}
.opening-title{
  margin-top:-50%;
  font-size:4rem;
  font-weight:400;
  text-transform: uppercase;
  font-family: 'Oswald', 'helvetica', sans-serif;
  text-shadow:0 0 20px rgba(255,255,255,1);
  @media only screen and (min-width: 480px) {
    margin-top:-15%;
  }
  @media only screen and (min-width: 768px) {
    font-size:5rem;
  }
  @media only screen and (min-width: 992px) {
    margin-top:-25%;
    font-size:8rem;
  }
  @media only screen and (min-width: 1200px) {
    margin-top:-30%;
    font-size:8rem;
  }
}

.font-oswald{
  font-family: 'Oswald', 'helvetica', sans-serif;
}

.font-yellowtail{
  font-family: 'Yellowtail', serif;
}

.hl-clients{
  font-size: 4rem;
}

.script-headline{
  font-family: 'Yellowtail', serif;
  font-size: 4rem;
}
.script-headline-primary{
  font-family: 'Yellowtail', serif;
  font-size: 4rem;
  color: #974631;
}

.client-slider{
  img{
    filter: grayscale(100%);
  }
}

.recent-work-title{
  margin-bottom:2rem;
}

.testimonial-title{
  color:$brand-primary;
  text-transform: uppercase;
}

.page-footer .copyright{
  background:$black url('../images/DarkBGPattern600.jpg') repeat center center;
}

figure.wp-caption{
  max-width:100%;
  height:auto;
  .wp-caption-text{
    font-size:0.8em;
    text-align:center;
  }
}

.about-bob-title{
  text-align:left;
  font-weight:500;
  font-size:3em;
  @media only screen and (min-width: 768px) {
    text-align:right;
  }
}

.dh-container {
	position: relative;
  overflow: hidden;
  padding:10px 10px 20px;
  .dh-overlay {
    transition: all 1s;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0;
    background: $brand-primary;
    border:2px solid darken($brand-primary, 25%);
  }
  &:hover .dh-overlay{
    opacity: 0.4;
  }
  .dh-download{
    transition: all 1s;
    position:absolute;
    bottom:5px;
    right:5px;
    opacity:0;
    z-index:3;
  }
  &:hover .dh-download{
    opacity:1;
  }
  .dh-item{
    text-align:center;
  }
}

.port-title{
  margin-top:-10px;
}

.well-bv{
  border-radius:5px;
  border:2px solid $brand-primary;
}

a.file-item[href$=".png"],a.file-item[href$=".jpg"],a.file-item[href$=".gif"],a.file-item[href$=".svg"],a.file-item[href$=".psd"]{
  &:before {
    content:"\f1c5  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".mp3"],a.file-item[href$=".aac"],a.file-item[href$=".wav"],a.file-item[href$=".ogg"]{
  &:before {
    content:"\f1c7  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".pdf"]{
  &:before {
    content:"\f1c1  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".doc"],a.file-item[href$=".docx"]{
  &:before {
    content:"\f1c2  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".xls"],a.file-item[href$=".xlsx"]{
  &:before {
    content:"\f1c3  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".zip"],a.file-item[href$=".rar"],a.file-item[href$=".tar"],a.file-item[href$=".gz"]{
  &:before {
    content:"\f1c6  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".txt"],a.file-item[href$=".md"]{
  &:before {
    content:"\f0f6  ";
    font-family: fontawesome;
  }
}
a.file-item[href$=".mov"],a.file-item[href$=".wmv"],a.file-item[href$=".mp4"],a.file-item[href$=".m4v"],a.file-item[href$=".mkv"],a.file-item[href$=".avi"],a.file-item[href$=".mpeg"]{
  &:before {
    content:"\f1c8  ";
    font-family: fontawesome;
  }
}

.header-btn {
  margin-top: -3px;
}
.btn-group-xs > .btn, .btn-xs, .comment-form .btn-group-xs > input[type="submit"], .search-form .btn-group-xs > .search-submit {
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1.0;
  border-radius: 0;
}

#wrap-5b240997488ff .block_media_image{
  max-width:230px;
  height:auto;
  display:block;
  margin-left:auto;
  margin-right:auto;
}
